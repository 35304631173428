import angular from 'angular';

import PartnershipsAdvertisersModule from './partnerships-advertisers/partnerships-advertisers.module.js';
import PartnershipsListModule from './partnerships-list/partnerships-list.module.js';
import PartnershipsViewModule from './partnerships-view/partnerships-view.module.js';

import PartnershipsController from './partnerships.controller.js';

import PartnershipsDuplicateController from './partnerships-duplicate.controller.js';
import PartnershipNewController from './partnerships-new.controller.js';
import UploadPartnershipCSVController from './partnerships-upload-csv.controller.js';
import PartnershipsConfig from './partnerships.config.js';

import partnershipsViewNavigationHtml from '../../views/partnerships/includes/view_navigation.html';

const PartnershipsModule = angular
	.module('partnerships', [PartnershipsAdvertisersModule, PartnershipsListModule, PartnershipsViewModule])
	.controller('PartnershipNewController', PartnershipNewController)
	.controller('AbstractPartnershipsController', PartnershipsController)
	.controller('PartnershipsDuplicateController', PartnershipsDuplicateController)
	.controller('UploadPartnershipCSVController', UploadPartnershipCSVController)
	.directive('partnershipsViewNavigation', () => ({
		scope: false,
		template: partnershipsViewNavigationHtml,
	}))
	.config(['$stateProvider', PartnershipsConfig]).name;

export default PartnershipsModule;
