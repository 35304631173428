import ROLE from './../constants/UserRoles.js';

function AuthService(UserInfo, Api, $http) {
	'ngInject';

	var redirectTo = '',
		redirectParams = {},
		sessionValid = true,
		isAuthenticated = false,
		loginError = null;

	/**
	 * @returns {boolean}
	 */
	this.hasRedirect = function hasRedirect() {
		return angular.isString(redirectTo) && !!redirectTo;
	};

	/**
	 * @returns {String}
	 */
	this.getRedirectState = function getRedirectState() {
		return redirectTo;
	};

	/**
	 * @params {String} toStateName
	 */
	this.setRedirectState = function setRedirectState(toStateName) {
		redirectTo = toStateName;
	};

	/**
	 * @returns {Object}
	 */
	this.getRedirectParams = function getRedirectParams() {
		return redirectParams;
	};

	this.getLoginError = function getLoginError() {
		return loginError;
	};

	this.setLoginError = function setLoginError(error) {
		loginError = error;
	};

	/**
	 * @params {Object} toStateParams
	 */
	this.setRedirectParams = function setRedirectParams(toStateParams) {
		redirectParams = toStateParams;
	};

	this.clearRedirect = function clearRedirect() {
		redirectTo = '';
		redirectParams = {};
	};

	/**
	 * @param {Array|String} requiredRoles
	 * @returns {boolean}
	 */
	this.authorizationNeeded = function authorizationNeeded(requiredRoles) {
		return angular.isDefined(requiredRoles);
	};

	/**
	 * @returns {boolean}
	 */
	this.isAuthorized = function isAuthorized(requiredRoles) {
		var roles = requiredRoles;

		// wrap if needed to allow single roles be passed as string
		if (!angular.isArray(roles)) {
			roles = [roles];
		}

		return this.isAuthenticated() && (roles.includes(ROLE.ALL) || roles.includes(UserInfo.getHighestRole()) || this.findAtLeastOneRole(roles));
	};

	/**
	 * @returns {boolean}
	 */
	this.isAuthenticated = function isAuthenticatedFn() {
		return sessionValid && isAuthenticated;
	};

	this.setSessionValid = function setSessionValid() {
		sessionValid = true;
	};

	this.setSessionExpired = function setSessionExpired() {
		sessionValid = false;
	};

	/**
	 * Manually set the user as authenticated.
	 */
	this.setAlreadyLoggedIn = function setAlreadyLoggedIn() {
		isAuthenticated = true;
	};

	/**
	 * @returns {Promise.<boolean>}
	 */
	this.verifyAuthenticationStatus = () =>
		Api.get('/auth-status')
			.then((result) => {
				isAuthenticated = result.isLoggedIn;

				return result.isLoggedIn;
			})
			.catch(() => false);

	/**
	 * Execute user logout attempt.
	 * @return {Promise}
	 */
	this.logout = () =>
		$http
			.post('/api/auth/signout', {
				headers: angular.extend({
					'Content-Type': 'application/json; charset=utf-8',
				}),
			})
			.then(() => {
				isAuthenticated = false;
			});

	/**
	 * Logout from dashboard session.
	 */
	this.dlo = function dlo() {
		// remove possible garbage from last logout
		this.clrdlo();

		// trigger logout
		angular.element(document).find('body').eq(0).append(angular.element('<iframe class="display-none" id="dashLogout" src="/tableau/logout"></iframe>'));
	};

	this.clrdlo = function clrdlo() {
		angular.element(document).find('#dashLogout').remove();
	};

	/**
	 * @param {String} requiredRole
	 * @returns {boolean}
	 */
	this.canAccess = function canAccess(requiredRole) {
		return this.isAuthenticated() && UserInfo.hasAtLeastRole(requiredRole);
	};

	/**
	 * @returns {boolean}
	 */
	this.isAdmin = function isAdmin() {
		return UserInfo.getRoles().includes(ROLE.ADMIN);
	};

	/**
	 * @returns {boolean}
	 */
	this.isYlAdmin = function isYlAdmin() {
		return UserInfo.getRoles().includes(ROLE.YL_ADMIN);
	};

	/**
	 * @returns {boolean}
	 */
	this.hasInsights = function hasInsights() {
		return this.canAccess(ROLE.REPORTS) && UserInfo.hasAllChannels();
	};

	/**
	 * Used to check if user has at least one of the required roles.
	 * @param {Array.<String>} requiredRoles
	 * @returns {boolean}
	 */
	this.findAtLeastOneRole = function findAtLeastOneRole(requiredRoles) {
		var i;

		for (i = 0; i < requiredRoles.length; i++) {
			if (UserInfo.getRoles().includes(requiredRoles[i])) {
				return true;
			}
		}

		return false;
	};
}

export default AuthService;
