import ROLE from './../../constants/UserRoles.js';

import adslotsHtml from '../../views/partnerships/adslots.html';
import advertisersHtml from '../../views/partnerships/advertisers.html';
import bidOptionsHtml from '../../views/partnerships/bid-options.html';
import categoryFilterHtml from '../../views/partnerships/category-filter.html';
import detailsHtml from '../../views/partnerships/details.html';
import filterHtml from '../../views/partnerships/filter.html';
import listHtml from '../../views/partnerships/list.html';
import navigationHtml from '../../views/partnerships/navigation.html';
import sizesHtml from '../../views/partnerships/sizes.html';
import targetingHtml from '../../views/partnerships/targeting.html';
import viewHtml from '../../views/partnerships/view.html';
import { loadAdslotsWithPrices, loadPartnershipAdslotPrices } from '../resolver-util.js';

function AppConfigRoutingPartnerships($stateProvider) {
	'ngInject';

	// controllerAs will be refactored step by step
	$stateProvider // eslint-disable-line angular/controller-as-route
		.state('partnerships', {
			parent: 'app',
			abstract: true,
			url: '/partnerships',
			views: {
				'subMenu@app': {
					template: navigationHtml,
				},
				'main@app': {
					template: '<div data-ui-view></div>',
					controller: 'AbstractPartnershipsController',
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})

		.state('partnerships.advertisers', {
			url: '/advertisers',
			template: advertisersHtml,
			controller: 'PartnershipsAdvertisersController',
			resolve: {
				availableAdvertisers: function availableAdvertisers(PartnershipsAdvertisers) {
					'ngInject';

					return PartnershipsAdvertisers.get();
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})

		.state('partnerships.list', {
			url: '/list',
			template: listHtml,
			controller: 'PartnershipsListController',
			resolve: {
				loadPartnerships: function loadPartnerships(Partnerships) {
					'ngInject';

					if (Partnerships.isReloadNeeded()) {
						return Partnerships.loadAll();
					}

					return true;
				},
				resolvedDcAccountSettings: function resolveDcAccountSettings(DirectConnectAccountSettings, Account, AuthService) {
					'ngInject';

					if (Account.isDirectConnect() && AuthService.isAdmin()) {
						return DirectConnectAccountSettings.load();
					}
					return false;
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('partnerships.list.view', {
			url: '/{id:[0-9]+}?adv',
			abstract: true,
			views: {
				'@partnerships': {
					template: viewHtml,
					controller: 'PartnershipsViewController',
				},
			},
			resolve: {
				resolvePartnershipExist: (Partnerships, $stateParams, toaster, $q) => {
					'ngInject';

					if (!Partnerships.getById($stateParams.id)) {
						toaster.errorMessage('TEXT.PARTNERSHIP_NOT_EXISTS');
						return $q.reject({
							error: 'could not load requested Partnership',
							data: $stateParams.id,
						});
					}
					return true;
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('partnerships.list.view.details', {
			url: '/details',
			template: detailsHtml,
			controller: 'PartnershipDetailsController',
			controllerAs: 'psDetails',
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
			resolve: {
				resolvedPartnership: function resolvedPartnership($stateParams, Partnerships) {
					'ngInject';

					return angular.copy(Partnerships.getById($stateParams.id));
				},
				resolvedDirectConnectOptions: function resolveDirectConnectOptions($stateParams, Account, PartnershipDirectConnectOptions) {
					'ngInject';

					return Account.isAllowedToUpdateDealMargin() ? PartnershipDirectConnectOptions.get($stateParams.id) : {};
				},
			},
		})
		.state('partnerships.list.view.bidoptions', {
			url: '/bid-options',
			template: bidOptionsHtml,
			controller: 'PartnershipBidOptionsController',
			controllerAs: 'psboCtrl',
			resolve: {
				resolvedPartnershipBidOptions: function resolvedPartnershipBidOptions(PartnershipBidOptions, $stateParams) {
					'ngInject';

					return PartnershipBidOptions.get($stateParams.id);
				},
				resolvedYieldprobeParameters: function resolvedYieldprobeParameters(PartnershipYieldprobeParameters, $stateParams) {
					'ngInject';

					return PartnershipYieldprobeParameters.get($stateParams.id).then(function success(parameters) {
						return PartnershipYieldprobeParameters.asArray(parameters);
					});
				},
				globalGroups(InventoryFilter) {
					'ngInject';

					return InventoryFilter.getReferrerGroups().then((groupsMap) => Object.values(groupsMap));
				},
				globallyActiveGroups: function globallyActiveGroups(InventoryFilter) {
					'ngInject';

					return InventoryFilter.getActiveReferrerGroups();
				},
				locallyActiveGroups: function locallyActiveGroups(PartnershipReferrerGroups, $stateParams) {
					'ngInject';

					return PartnershipReferrerGroups.get($stateParams.id);
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('partnerships.list.view.sizes', {
			url: '/sizes',
			template: sizesHtml,
			controller: 'PartnershipSizesController',
			controllerAs: 'psSizesCtrl',
			resolve: {
				selectedPartnership: ($stateParams, Partnerships) => {
					'ngInject';

					return Partnerships.getById($stateParams.id);
				},
				supplyPartnerDimensions: (Account) => {
					'ngInject';

					return Account.getSupplyPartnerDimensions();
				},
				resolvedPrices: function resolvedPrices(PartnershipAdslots, $stateParams) {
					'ngInject';

					return loadPartnershipAdslotPrices(PartnershipAdslots, $stateParams.id);
				},
				resolvedAdslots: function resolveAdslots(resolvedPrices, Adslots) {
					'ngInject';

					return loadAdslotsWithPrices(resolvedPrices, Adslots);
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('partnerships.list.view.adslots', {
			url: '/adslots',
			template: adslotsHtml,
			controller: 'PartnershipAdslotsController',
			resolve: {
				loadSites: function loadSites(Sites) {
					'ngInject';

					return Sites.loadAll();
				},
				loadAdslotGroups: function loadAdslotGroups(AdslotGroups) {
					'ngInject';

					return AdslotGroups.loadAll();
				},
				resolvedPrices: function resolvedPrices(PartnershipAdslots, $stateParams) {
					'ngInject';

					return loadPartnershipAdslotPrices(PartnershipAdslots, $stateParams.id);
				},
				resolvedAdslots: function resolvedAdslots(resolvedPrices, Adslots) {
					'ngInject';

					return loadAdslotsWithPrices(resolvedPrices, Adslots);
				},
				resolvedGuaranteedAdslots($stateParams, PGAdslots, Partnerships) {
					'ngInject';

					return Partnerships.getById($stateParams.id).isProgrammaticGuaranteed() ? PGAdslots.getList() : [];
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('partnerships.list.view.filter', {
			url: '/filter',
			template: filterHtml,
			controller: 'PartnershipFilterController',
			controllerAs: '$ctrl',
			resolve: {
				partnershipId($stateParams) {
					'ngInject';

					return $stateParams.id;
				},
				resolvedFilter(PartnershipFilter, $stateParams) {
					'ngInject';

					return PartnershipFilter.load($stateParams.id);
				},
				resolvedBlockedAdvertisers(PartnershipFilter, $stateParams) {
					'ngInject';

					return (
						PartnershipFilter.getBlockedAdvertisers($stateParams.id)
							// flatten blocked advertisers to unpack blockedByAdslot entries
							// blockedBySupply and blockedByPartnership will always be of length == 1
							// and should show up as separate entry in the resulting list
							.then((blockedAdvertisers) =>
								blockedAdvertisers.reduce((flattenedBlockedAdvertisers, blockedAdvertiser) => {
									const { id, name, blockedBySupply, blockedByPartnership, blockedByAdslot } = blockedAdvertiser;

									if (blockedBySupply.length > 0) {
										flattenedBlockedAdvertisers.push({
											type: 's',
											id,
											name,
											entityId: blockedBySupply[0],
										});
									}

									if (blockedByPartnership.length > 0) {
										flattenedBlockedAdvertisers.push({
											type: 'p',
											id,
											name,
											entityId: blockedByPartnership[0],
										});
									}

									return flattenedBlockedAdvertisers.concat(
										blockedByAdslot.map((entityId) => ({
											type: 'a',
											id,
											name,
											entityId,
										})),
									);
								}, []),
							)
					);
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		})
		.state('partnerships.list.view.category-filter', {
			url: '/category-filter',
			template: categoryFilterHtml,
			controller: 'PartnershipCategoryFilterController',
			resolve: {
				resolvedPartnershipCategoryFilter: function resolvedPartnershipCategoryFilter(PartnershipCategoryFilter, $stateParams) {
					'ngInject';

					return PartnershipCategoryFilter.get($stateParams.id);
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER, ROLE.REPORTS],
			},
		})
		.state('partnerships.list.view.targeting', {
			url: '/targeting',
			template: targetingHtml,
			controller: 'PartnershipTargetingController',
			controllerAs: 'psTargetingCtrl',
			resolve: {
				resolvedTargetingConfiguration: function resolvedTargetingConfiguration(PartnershipTargeting, $stateParams) {
					'ngInject';

					return PartnershipTargeting.get($stateParams.id);
				},
			},
			data: {
				authorizedRoles: [ROLE.ADMIN, ROLE.USER],
			},
		});
}

export default AppConfigRoutingPartnerships;
