function ResetPasswordController(Password, toaster, InfoService, $stateParams) {
	'ngInject';

	var vm = this;

	vm.success = false;
	vm.code = decodeURIComponent($stateParams.code || '');
	vm.password = '';
	vm.passwordAgain = '';

	vm.notDifferentToPreviousError = false;

	vm.hasMinLength = function hasMinLength() {
		return Password.checks.hasMinLength(vm.password);
	};

	vm.containsNumber = function containsNumber() {
		return Password.checks.containsNumber(vm.password);
	};

	vm.containsLetter = function containsLetter() {
		return Password.checks.containsLetter(vm.password);
	};

	vm.hasNoSpace = function hasNoSpace() {
		return Password.checks.hasNoSpace(vm.password);
	};

	vm.isFormValid = function isFormValid() {
		return vm.password === vm.passwordAgain && vm.hasMinLength() && vm.containsNumber() && vm.containsLetter() && vm.hasNoSpace();
	};

	vm.resetPassword = function resetPassword() {
		InfoService.startRequest();
		Password.reset(vm.code, vm.password)
			.then(function success() {
				vm.success = true;
				toaster.successMessage('MESSAGE.PASSWORD_CHANGE_SUCCESS');
				InfoService.endRequest();
			})
			.catch(function error(errorObject) {
				var errorMsg = 'MESSAGE.PASSWORD_CHANGE_ERROR';

				if (errorObject.code === Password.ERROR.INVALID_RESET_CODE) {
					vm.resetForm.code.$setValidity('required', false);
					errorMsg = 'MESSAGE.PASSWORD_INVALID_CODE';
				} else if (errorObject.code === Password.ERROR.SAME_AS_LAST_PASSWORD) {
					vm.notDifferentToPreviousError = true;
					vm.resetForm.pass.$setValidity('required', false);
					errorMsg = 'MESSAGE.PASSWORD_SAME_AS_BEFORE';
				}

				if (errorMsg) {
					toaster.errorMessage(errorMsg);
				}

				InfoService.endRequest();
			});
	};
}

export default ResetPasswordController;
