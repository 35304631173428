function Password(Api) {
	'ngInject';

	/**
	 * @param {String} password
	 * @returns {boolean}
	 */
	function hasMinLength(password) {
		return !!password && password.length >= 5;
	}

	/**
	 * @param {String} password
	 * @returns {boolean}
	 */
	function containsNumber(password) {
		return !!password && password.match(/\d/);
	}

	/**
	 * @param {String} password
	 * @returns {boolean}
	 */
	function containsLetter(password) {
		return !!password && password.match(/[a-zA-Z]/);
	}

	/**
	 * @param {String} password
	 * @returns {boolean}
	 */
	function hasNoSpace(password) {
		return !!password && !password.match(/\s/);
	}

	/**
	 * @type {{WRONG_PASSWORD: number, INVALID_PASSWORD: number, INVALID_RESET_CODE: number}}
	 */
	this.ERROR = {
		WRONG_PASSWORD: 20001,
		INVALID_PASSWORD: 20002,
		INVALID_RESET_CODE: 20003,
		SAME_AS_LAST_PASSWORD: 20004,
	};

	/**
	 * @param {String} oldPassword
	 * @param {String} newPassword
	 * @returns {promise}
	 */
	this.change = (oldPassword, newPassword) =>
		Api.post('/api/v1/users/changePassword', {
			oldPassword,
			newPassword,
		});

	/**
	 * @param {String} email
	 * @returns {promise}
	 */
	this.requestResetLink = (email) =>
		Api.post('/api/v1/users/forgotPassword', {
			email,
		});

	/**
	 * @param {string} code
	 * @param {string} newPassword
	 * @returns {promise}
	 */
	this.reset = (code, newPassword) =>
		Api.post('/api/v1/users/resetPassword', {
			code,
			newPassword,
		});

	this.checks = {
		hasMinLength: hasMinLength,
		containsNumber: containsNumber,
		containsLetter: containsLetter,
		hasNoSpace: hasNoSpace,
	};
}

export default Password;
